<template>
  <v-container class="wp-page recipe-list" @click="clicked">
    <breadcrumb :items="breadcrumb" />
    <div class="recipe-header mb-2" v-if="page">
      <v-img
        :src="page.featured_image_url"
        heigth="250"
        min-height="250"
        class="d-flex justify-center align-center rounded"
      >
        <div
          class="pa-10 white rounded-circle d-flex align-center justify-center recipe-header-icon"
        >
          <v-img min-width="42" :src="`/img_layout/wordpress/${cuisine}.svg`" />
        </div>
        <h1 v-html="title" class="text-center white--text"></h1>
        <div v-html="content" class="text-center white--text"></div>
      </v-img>
    </div>
    <v-row class="d-flex justify-end" v-if="cuisine === 'appuntino'">
      <RecipeFilterLite></RecipeFilterLite>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
        xl="3"
        v-for="recipe in postList"
        :key="recipe.id"
      >
        <PostCard :post="recipe" :isRecipe="true" />
      </v-col>
    </v-row>
    <v-pagination
      class="mt-12"
      color="secondary"
      v-if="pager && pager.totPages > 1"
      v-model="pageNum"
      :length="pager.totPages ? pager.totPages : 0"
      :totalVisible="$vuetify.breakpoint.xs ? 4 : 6"
      @next.stop.prevent="handlePageFilter"
      @prev="handlePageFilter"
      @input="handlePageFilter"
    ></v-pagination>
  </v-container>
</template>
<style scoped lang="scss">
@import url("/p/wp-content/themes/sidal/styles-common.css?ver=wp");
.recipe-list {
  .recipe-header {
    .recipe-header-icon {
      display: block;
      margin: 0 auto;
      width: 95px;
      height: 95px;
      img {
        height: 50px;
        max-height: 50px;
      }
    }
  }
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";
import CMService from "~/service/cmService";

import PostCard from "@/components/wordpress/PostCard.vue";
import RecipeFilterLite from "@/components/wordpress/RecipeFilterLite.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import page from "~/mixins/page";
import get from "lodash/get";

export default {
  name: "RecipeList",
  mixins: [page, clickHandler],
  components: {
    Breadcrumb,
    PostCard,
    RecipeFilterLite
  },
  props: {
    s: {
      type: String,
      default: null
    },
    course: {
      type: String,
      default: null
    },
    cuisine: {
      type: String,
      default: null
    },
    video: {
      type: String,
      default: null
    },
    pageNum: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      postList: null,
      pager: {},
      noPostToShow: false
    };
  },

  methods: {
    async fetchPosts() {
      let params = [];

      if (this.s != null && this.s.length > 0) {
        params.push({
          filterName: "filter[s]",
          filterValue: this.s
        });
      }
      if (this.course != null && this.course.length > 0) {
        params.push({
          filterName: "filter[course]",
          filterValue: this.course
        });
      }
      if (this.cuisine != null && this.cuisine.length > 0) {
        params.push({
          filterName: "filter[cuisine]",
          filterValue: this.cuisine
        });
      }
      if (this.video != null && this.video.length > 0) {
        params.push(
          {
            filterName: "filter[taxonomy]",
            filterValue: "chefs"
          },
          {
            filterName: "filter[term]",
            filterValue: this.video
          }
        );
      }
      const res = await CMService.getCustomPostByFilters(
        "recipe",
        params,
        12,
        this.pageNum
      );

      if (res && res.data && res.data.length) {
        this.pager = res.page;
        this.postList = res.data;
        this.noPostToShow = false;
      } else {
        this.postList = null;
        this.noPostToShow = true;
      }
    },
    handlePageFilter(page) {
      let query = {
        page: page
      };
      if (this.s) {
        query.s = this.s;
      }
      if (this.course) {
        query.course = this.course;
      }
      if (this.video) {
        query.video = this.video;
      }
      this.$router.push({
        name: this.$route.name,
        query: query
      });
    }
  },
  mounted() {
    const _this = this;
    _this.page = _this.$route.params.page;
    _this.fetchPosts();
  },
  watch: {
    // s() {
    //   this.fetchPosts();
    // },
    // cuisine() {
    //   this.fetchPosts();
    // },
    course() {
      this.fetchPosts();
    },
    // video() {
    //   this.fetchPosts();
    // },
    pageNum() {
      this.fetchPosts();
    }
  },
  jsonld() {
    if (this.page) {
      return {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": this.page.link
        },
        headline: this.page.meta ? this.page.meta.seo_title : "",
        image: ["/logo/social-logo.png"],
        author: {
          "@type": "Organization",
          name: global.config?.defaultPageName
        },
        datePublished: this.page.date,
        dateModified: this.page.date,
        publisher: {
          "@type": "Organization",
          name: global.config?.defaultPageName,
          logo: {
            "@type": "ImageObject",
            url: "/logo/social-logo.png"
          }
        }
      };
    }
  },
  metaInfo() {
    return {
      title: get(this.page, "meta.seo_title"),
      link: [
        {
          rel: "canonical",
          href: `https://${window.location.host}${this.$route.path}`
        }
      ],
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(this.page, "meta.seo_description")
        },
        // Open Graph Metas
        {
          property: "og:locale",
          content: "it_IT"
        },
        {
          property: "og:title",
          content: get(
            this.page,
            "meta.seo_title",
            global.config?.defaultPageName
          )
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:url",
          content: location.href
        },
        {
          property: "og:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          property: "og:image:alt",
          content: get(this.page, "meta.seo_description")
        },
        {
          property: "og:site_name",
          content: global.config?.defaultPageName
        },
        // Twitter Metas
        {
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          name: "twitter:title",
          content: get(this.page, "meta.seo_title", "Pagina statica")
        },
        {
          name: "twitter:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          name: "twitter:url",
          content: location.href
        }
      ]
    };
  }
};
</script>
